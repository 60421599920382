import React, { useEffect, useState } from "react";
import Select from "react-select";

const Countries = ({selectedCountry, setSelectedCountry}) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
        .then((response) => response.json())
        .then((data) => {
            setCountries(data.countries)
            setSelectedCountry(data.userSelectValue)
        })
    }, []);

    return(
        <Select
            styles={{
                control: (base, state) => ({
                    ...base,
                    minHeight: 37,
                    border: state.isFocused ? '1px solid #4c187f' : '1px solid #ccc',
                    '&:hover': {
                        border: '1px solid #4c187f'
                    },
                    boxShadow: 'none',
                    cursor: 'pointer'
                }),
                singleValue: (base, state) => ({
                    ...base,
                    color: '#4c187f'
                }),
                valueContainer: (base, state) => ({
                    ...base,
                    padding: '0 0 0 10px'
                }),
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#ebebeb' : '#fff',
                    color: '#4c187f',
                    '&:hover': {
                      backgroundColor: '#ebebeb',
                      color: '#ec6c3a'
                    },
                    cursor: 'pointer'
                }),
                menu: (base, state) => ({
                    ...base,
                    marginTop: 0,
                    marginBottom: 0
                }),
                menuList: (base, state) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0
                }),
                dropdownIndicator: (base, state) => ({
                    ...base,
                    height: 21,
                    width: 21,
                    color: '#4c187f',
                    padding: 0,
                    '&:hover': {
                      color: '#4c187f'
                    },
                    transition: '0.3s transform ease-in-out',
                    transform: state.selectProps.menuIsOpen && 'rotateX(180deg)'
                }),
                indicatorSeparator: (base, state) => ({
                    ...base,
                    display: 'none'
                })
            }}
            className="Country-select-container"
            classNamePrefix="Country-select"
            options={countries}
            value={selectedCountry}
            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
        />
    )
}

export default Countries