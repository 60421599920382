import React from "react"
import '../css/Spinner.css'

export default function SPLoader() {
    return(
        <div className="App-loading">
            <div className="App-spinner">
                <img className="Spinner-anim" src="/app/images/spinner.svg"/>
                <img className="Spinner-logo" src="https://www.chargein.com/wp-content/uploads/2023/10/cropped-favicon.png"/>
            </div>
        </div>
    )
}