import React, { useState } from "react"
import '../css/Popup.css'

export default function Popup({ header, content , isRetrieved}) {
    const [popup, setPopup] = useState(false);
    
    const togglePopup = () => {
        setPopup(!popup);
        isRetrieved(false);
    }

    return(
        <div className="App-popup">
            <div
            className="overlay"
            onClick={togglePopup}></div>
            <div className="popup-content">
                <h2>{header}</h2>
                <p>{content}</p>
                <button 
            className="close-popup"
            onClick={togglePopup}>x</button>
            </div>
        </div>
    )
}