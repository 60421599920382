import React, { useState } from 'react'
import Countries from '../components/Countries';
import '../css/Invoice.css'

const Invoice = ({ onSubmit, lang, setLang }) => {
    const [isPrivate, setPrivate] = useState(true);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [tax, setTax] = useState('');
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({});
    const [consent, setConsent] = useState(false);
    const [error, setError] = useState(null);
    const url = window.location.href;
    const session = url.split("/").pop();

    const toggleSelectBox = () => {
        const box = document.getElementById('Payment-type');
        box.classList.toggle('active');
    }

    const validateEmail = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(input);
    }

    const validatePhone = (input) => {
        const phoneRegex = /^(\+48)?[ ]?(\d{3})[- ]?(\d{3})[- ]?(\d{3})$/;
        return phoneRegex.test(input);
    }

    const validateZip = (input) => {
        const zipRegex = /^(\d{2})[-]?(\d{3})$/;
        return zipRegex.test(input);
    }

    const validateTax = (input) => {
        const taxRegex = /^(\d{10})$/;
        return taxRegex.test(input);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        if (!validatePhone(phone) && selectedCountry.value === 'PL') {
            setError(lang == 'PL' ? 'Numer telefonu jest w złym formacie!' : "The phone number is in the wrong format!");
            return;
        }

        if (!validateEmail(email)) {
            setError(lang == 'PL' ? 'Adres e-mail jest w złym formacie!' : 'The e-mail address is in the wrong format!');
            return;
        }

        if (!validateTax(tax) && !isPrivate && selectedCountry.value === 'PL') {
            setError(lang == 'PL' ? 'Kod NIP powinien składać się z 10 cyfr!' : 'The NIP number should consist of 10 digits!');
            return;
        }

        if (!validateZip(zip) && selectedCountry.value === 'PL') {
            setError(lang == 'PL' ? 'Kod pocztowy jest w złym formacie!' : 'The zip code is in the wrong format!');
            return;
        }

        if (!consent) {
            setError(lang == 'PL' ? 'Proszę wyrazić zgodę na przetwarzanie danych osobowych!' : 'Please consent to the processing of personal data!');
            return;
        }

        onSubmit(name, surname, phone, email, company, tax, street, zip, city, selectedCountry.value, session, isPrivate, lang);
    }

    return (
        <div>
            <div className="App-lang">
                <svg onClick={() => {setLang('PL')}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10"><rect width="16" height="10" fill="#fff"/><rect width="16" height="5" fill="#dc143c" y="5"/></svg>
                <svg onClick={() => {setLang('ENG')}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30"><clipPath id="t"><path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/></clipPath><path d="M0,0v30h50v-30z" fill="#012169"/><path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/><path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/><path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/></svg>
            </div>
            <h2>{lang == 'PL' ? 'Faktura za ładowanie' : 'EV charging invoice'}</h2>
            <form onSubmit={handleSubmit}>
            <div className="App-input">
                    <label>{lang == 'PL' ? 'Typ płatności:' : 'Payment type:'}</label>
                    <div className='Select-box'>
                        <div className='Options-container' id="Payment-type">
                            <div
                                className='Option'
                                onClick={() => {
                                    setPrivate(true)
                                    toggleSelectBox()
                                }}
                            >
                                <input type="radio"/>
                                <label>{lang == 'PL' ? `Prywatna` : 'Private'}</label>
                            </div>
                            <div
                                className='Option'
                                onClick={() => {
                                    setPrivate(false)
                                    toggleSelectBox()
                                }}
                            >
                                <input type="radio"/>
                                <label>{lang == 'PL' ? 'Firma' : 'Company'}</label>
                            </div>
                        </div>
                        <div
                            className="Selected"
                            onClick={toggleSelectBox}
                        >
                            <span>{isPrivate ? (lang == 'PL' ? 'Prywatna' : 'Private') : (lang == 'PL' ? 'Firma' : 'Company')}</span>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="Selected-arrow"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                        </div>
                    </div>
                </div>
                {isPrivate && 
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Imię:' : 'Name:'}</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        required
                    />
                </div>}
                {isPrivate && 
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Nazwisko:' : 'Surname:'}</label>
                    <input
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        required
                    />
                </div>}
                {!isPrivate && 
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Nazwa firmy:' : 'Company name:'}</label>
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        required
                    />
                </div>}
                {!isPrivate && 
                <div className="App-input">
                    <label>{lang == 'PL' ? 'NIP:' : 'Tax number:'}</label>
                    <input
                        type="text"
                        value={tax}
                        onChange={(e) => {
                            const re = /^[0-9]+$/;
                            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 11){
                                setTax(e.target.value)}
                            }
                        }
                        required
                    />
                </div>}
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Telefon:' : 'Phone number:'}</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={(e) => {
                            const re = /^[0-9\+\- ]+$/;
                            if (e.target.value === '' || re.test(e.target.value)){
                                setPhone(e.target.value)}
                            }
                        }
                        required
                    />
                </div>
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Email:' : 'Email:'}</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Ulica:' : 'Street:'}</label>
                    <input
                        type="text"
                        value={street}
                        onChange={(e) => setStreet(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        required
                    />
                </div>
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Kod pocztowy:' : 'Zip code:'}</label>
                    <input
                        type="text"
                        value={zip}
                        onChange={(e) => {
                            const re = /^[0-9\-]+$/;
                            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 7){
                                setZip(e.target.value)}
                            }
                        }
                        required
                    />
                </div>
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Miasto:' : 'City:'}</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        required
                    />
                </div>
                <div className="App-input">
                    <label>{lang == 'PL' ? 'Kraj:' : 'Country:'}</label>
                    <Countries selectedCountry ={selectedCountry} setSelectedCountry={setSelectedCountry}/>
                </div>
                <div className="Consent-div">
                    <input
                        type="checkbox"
                        onChange={(e) => setConsent(e.target.checked)}
                    />
                    <p>
                    {lang == 'PL' ? 'Wyrażam zgodę na przetwarzanie moich danych osobowych i akceptuję ' :  'I consent to the processing of my personal data and accept the '}
                    <a href="https://www.chargein.com/doc/">
                        {lang == 'PL' ? 'regulamin' : 'regulations'}
                    </a>
                    {lang == 'PL' ? ' w celu kontaktu w związku ze złożonym zapytaniem lub zgłoszeniem przesłanym przez formularz kontaktowy*' :' for the purpose of contact in connection with the submitted inquiry or notification sent via the contact form*'}
                    </p>
                </div>
                {error && <div className="Error-div"><p>{error}</p></div>}
                <div className='Submit-div'>
                    <button type="submit">{lang == 'PL' ? 'Wyślij' : 'Send'}</button>
                </div>
            </form>
        </div>
    )
}

export default Invoice