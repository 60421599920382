import React, { useState } from 'react'
import axios from 'axios'
import Invoice from './pages/Invoice'
import SPLoader from './components/Spinner'
import Popup from './components/Popup'

function App() {
  const [isLoading, setIsLoading] = useState(false)
  const [isRetrieved, setIsRetrieved] = useState(true)
  const [lang, setLang] = useState('PL');
  const [description, setDescription] = useState('Generacja faktur jest w tej chwili niedostępna. W celu wygenerowania faktury prosimy o kontant z naszym biurem: biuro@chargein.com');
  const [title, setTitle] = useState('Przepraszamy!');

  const fetchData = async (name, surname, phone, email, company, tax, street, zip, city, country, session, isPrivate) => {
    try {
        setIsLoading(true)
        const response = await axios.post(
            '/api/invoice',
            { name, surname, phone, email, company, tax, street, zip, city, country, session, isPrivate, lang },
            { headers: {'Content-Type': 'application/json'} }
        )
  
        if (response.status === 200) {
          setTitle(lang == 'PL' ? 'Dziękujemy za korzystanie z naszych usług!' : 'Thank you for using our services');
          setDescription(lang == 'PL' ? 'Faktura za ładowanie została wysłana na podany adres e-mail!' : 'The ev charging invoice has been sent to the provided e-mail!');
        }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        setTitle(lang == 'PL' ? 'Przepraszamy! Wystąpił błąd!' : 'An error has occured!');
        setDescription(error.response.data.error || (lang == 'PL' ? 'Wystąpił błąd podczas przetwarzania Twojej prośby.' : 'An error has occured while processing your request.'));
      } else if (error.request) {
        // Request was made but no response was received
        setTitle(lang == 'PL' ? 'Przepraszamy! Wystąpił błąd!' : 'An error has occured!');
        setDescription(lang == 'PL' ? 'Brak odpowiedzi od serwera. Spróbuj ponownie później.' : 'No response from the server. Please try again later.');
      } else {
        // Something happened in setting up the request
        setTitle(lang == 'PL' ? 'Przepraszamy! Wystąpił błąd!' : 'An error has occured!');
        setDescription(lang == 'PL' ? 'Wystąpił błąd podczas przygotowywania żądania.' : 'An error has occured while preparing the request.' + error.message);
      }
    } finally {
      setIsLoading(false)
      setIsRetrieved(true)
    }
  };

  return(
    <div className="App">
      {isRetrieved && <Popup header={title} content={description} isRetrieved={setIsRetrieved}/>}
      {isLoading && <SPLoader />}
      <div className="App-container">
        <Invoice onSubmit={fetchData} lang={lang} setLang={setLang}/>
      </div>
    </div>
  )
}

export default App